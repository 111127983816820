import { resolve } from 'path'

export default {
  props: {
    tag: {
      type: String,
    },

    title: {
      type: String,
      required: true,
    },

    body: {
      type: String,
    },

    links: {
      type: Array,
    },

    button: {
      type: Object,
    },

    socialProof: {
      type: Object,
    },

    image: {
      type: Object,
    },

    component: {
      type: Object,
    },

    colorTitle: {
      type: String,
      default: 'gray',
      validator: prop => ['gray', 'orange'].includes(prop),
    },

    mobileImageBottom: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasTag() {
      const hasTag = this.tag

      if (hasTag) return true
    },

    hasTitle() {
      const hasTitle = this.title

      if (hasTitle) return true
    },

    hasBody() {
      const hasBody = this.body

      if (hasBody) return true
    },

    hasLinks() {
      const hasLinks = this.links

      if (hasLinks) return true
    },

    hasButton() {
      const hasButton = this.button

      if (hasButton) return true
    },

    hasSocialProof() {
      const hasSocialProof = this.socialProof

      if (hasSocialProof) return true
    },

    hasPlaceholderImage() {
      const hasPlaceholderImage = this.image

      if (hasPlaceholderImage) return true
    },

    hasPlaceholderComponent() {
      const hasPlaceholderComponent = this.component

      if (hasPlaceholderComponent) return true
    },

    componentInstance() {
      // TODO: ESTUDAR MELHOR A IMPORTAÇÃO DINÂMICA E O BUG DO WEBPACK QUE AFETA OUTROS ARQUIVOS
      // const componentName = JSON.stringify(this.component.name).replace(/\"/gim, '')
      // const component = `${componentName}/${componentName}.vue`
      // return async () => await import(`~/components/${component}`)
      // return async () => await import(/* webpackChunkName: "components/[request]" */ `~/components/${component}`)

      if (this.component.name === 'PaymentMethodsItems')
        return async () => await import('~/components/Molecules/PaymentMethodsItems/PaymentMethodsItems.vue')

      return {}
    },

    colorTitleClass() {
      return `title--${this.colorTitle}`
    },

    hasMobileImageBottom() {
      return this.mobileImageBottom
    },
  },
}
